//
// Copyright (C) 2012 - present Instructure, Inc.
//
// This file is part of Canvas.
//
// Canvas is free software: you can redistribute it and/or modify it under
// the terms of the GNU Affero General Public License as published by the Free
// Software Foundation, version 3 of the License.
//
// Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
// WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
// A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
// details.
//
// You should have received a copy of the GNU Affero General Public License along
// with this program. If not, see <http://www.gnu.org/licenses/>.

import _ from 'underscore'
import Backbone from 'node_modules-version-of-backbone'

// normalize (i.e. I18n) and filter errors we get from the API
Backbone.Model.prototype.normalizeErrors = function (errors, validationPolicy) {
  const result = {}
  let errorMap = this.errorMap || this.constructor.prototype.errorMap || {}
  if (_.isFunction(errorMap)) errorMap = errorMap(validationPolicy)

  if (errors) {
    for (const attr in errors) {
      const attrErrors = errors[attr]
      if (errorMap[attr]) {
        attrErrors.forEach((error) => {
          if (errorMap[attr][error.type]) {
            (result[attr] || (result[attr] = [])).push(errorMap[attr][error.type])
          }
        })
      }
    }
  }
  return result
}

export default Backbone.Model
